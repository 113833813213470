import { render, staticRenderFns } from "./TermsAndConditions.html?vue&type=template&id=433e73ac&scoped=true&external"
import script from "./TermsAndConditions.js?vue&type=script&lang=js&external"
export * from "./TermsAndConditions.js?vue&type=script&lang=js&external"
import style0 from "./TermsAndConditions.scss?vue&type=style&index=0&id=433e73ac&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "433e73ac",
  null
  
)

export default component.exports